import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { SortProps, HeadCellType } from '../../types.tsx';
import { HeadCell } from './table-head-cell/index.tsx';

export interface TableHeadProps extends SortProps {
	cells: HeadCellType[];
}

export const TableHead = ({ cells, sortOrder, sortKey, onSort }: TableHeadProps) => (
	<TableHeadContainer>
		<TableHeadRowContainer>
			{cells.map((headCell) => (
				<HeadCell
					{...headCell}
					key={headCell.key}
					onSort={onSort}
					sortOrder={sortOrder}
					sortKey={sortKey}
					cellKey={headCell.key}
				/>
			))}
		</TableHeadRowContainer>
	</TableHeadContainer>
);

const SortIconContainerSelector = 'simple-table-sort-icon-container';
const SortableIconCellContentWrapperSelector = 'simple-table-sortable-icon-cell-content-wrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHeadContainerOld = styled.thead({
	verticalAlign: 'middle',
	border: 'none',
	inset: 0,
	position: 'relative',
});

export const TableHeadContainer = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_2'),
	({ children }: PropsWithChildren<{}>) => (
		<Box as="thead" xcss={tableHeadStyles}>
			{children}
		</Box>
	),
	({ children }: PropsWithChildren<{}>) => (
		<TableHeadContainerOld>{children}</TableHeadContainerOld>
	),
);

const tableHeadStyles = xcss({
	verticalAlign: 'middle',
	inset: 'space.0',
	position: 'relative',
	border: `1px solid ${token('color.border')}`,
	backgroundColor: 'elevation.surface.sunken',
	borderTopLeftRadius: 'border.radius.200',
	height: 'space.500',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHeadRowContainerOld = styled.tr({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4.5}px`,
	borderWidth: 'initial',
	borderStyle: 'none',
	borderColor: 'initial',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortIconContainerSelector}"], &:focus [data-component-selector="${SortIconContainerSelector}"]`]:
		{
			opacity: 0.5,
			marginLeft: token('space.050'),
		},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortableIconCellContentWrapperSelector}"], &:focus [data-component-selector="${SortableIconCellContentWrapperSelector}"]`]:
		{
			opacity: 0,
		},
});

export const TableHeadRowContainer = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_2'),
	({ children }: PropsWithChildren<{}>) => <Box as="tr">{children}</Box>,
	({ children }: PropsWithChildren<{}>) => (
		<TableHeadRowContainerOld>{children}</TableHeadRowContainerOld>
	),
);
